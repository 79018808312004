<template>
  <div class='flex flex-col lg:flex-row justify-between items-stretch gap-x-20'>
    <khrs-about-page-navigation class='flex-shrink-0' />
    <demo-speakers 
      speaker-card-width='13rem'
      title='Faculty' />
  </div>
</template>

<script>
import KhrsAboutPageNavigation from '@/components/khrs-virtual/KhrsAboutPageNavigation.vue'
import DemoSpeakers from '@/views/demo/Speakers.vue'

export default {
  name: 'Overview',
  components: {
    KhrsAboutPageNavigation,
    DemoSpeakers,
  },
}
</script>
